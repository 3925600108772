// Features Slider (main page)
$(".features-slider").slick({
    arrows: false
});

$( '.features-sidebar').on( 'click', '.switch', function(e) {
    e.preventDefault();
    var selectedSlide = $(this).attr("data-attr");
    $(".features-slider").slick('slickGoTo', selectedSlide);

    $('.features-sidebar .switch').removeClass('active');
    $(this).addClass('active');
});


$('.features-slider').on('afterChange', function(event, slick, currentSlide, nextSlide){
    $('.features-sidebar .switch').removeClass('active');
    $('.features-sidebar .switch[data-attr=' + currentSlide + ']').addClass('active');
});


// Highlight chosen package in the pop up window
$('.choose-package').on('click', function () {
    var $package = $(this).data('package');

    $('#packagesModal').find('table tr td').removeClass('active');

    if($package == 1){
        $('#packagesModal').find('table tr td:nth-child(2)').addClass('active');
    }
    else if($package == 2){
        $('#packagesModal').find('table tr td:nth-child(3)').addClass('active');
    }
    else {
        $('#packagesModal').find('table tr td:nth-child(4)').addClass('active');
    }

});


// Close Popup once "contact us to begin" is clicked

$('.contact').on('click', function(){
    $('.modal').click();
});

// Contact Form
jQuery(document).ready(function(){
    $('input[name="package"]').on('change', function() {
        console.log(jQuery('input[name="package"]:checked').val());
     });
    jQuery('#ajaxSubmit').click(function(e){
        var $validation = $('#contact-form')[0].checkValidity();

        if($validation) {
            e.preventDefault();

            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });
            jQuery.ajax({
                url: $('#contact-form').attr('action'),
                method: 'post',
                data: {
                    name: jQuery('#name').val(),
                    email: jQuery('#email').val(),
                    company_name: jQuery('#company_name').val(),
                    job_title: jQuery('#job_title').val(),
                    message: jQuery('#message').val(),
                    package: jQuery('input[name="package"]:checked').val()
                },
                success: function(result){
                    if(result == 1){
                        $('#contactForm .success').show();
                        $('#contactForm').find("input").not('input[name="package"]').val("");
                        $('#contactForm').find("textarea").val("");
                        $("#core").prop("checked", true);
                        $('#contactForm #agree').prop( "checked", false );
                    }
                    else {
                        $('#contactForm .fail').show();
                    }

                },
                error: function (){
                    $('#contactForm .fail').show();
                }
            });
        }

    });
});
